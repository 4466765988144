<template>
  <v-footer
    class="py-4"
    dark
    height="auto"
  >
    <v-container class="mx-auto">
      <v-row>
        <v-col cols="4">
          <span style="font-size: 75%;">
            This page is up since September 8, 2022<br/>
            The images on the list pages were created using
            the <a href="https://stability.ai/blog/stable-diffusion-public-release" target="_blank">Stable Diffusion</a>
            and <a href="https://openai.com/dall-e-2" target="_blank">DALL-E 2</a> AI image generators.
          </span>
        </v-col>
        <v-col cols="6">
          <base-btn
            v-for="(item, i) in items"
            :key="i"
            :href="item.href"
            class="ml-0 mr-3"
            color="primary"
            square
            target="_blank"
          >
            <v-icon v-text="item.icon" />
          </base-btn>
        </v-col>

        <v-spacer />

        <base-btn
          class="mr-0"
          square
          title="Go to top"
          @click="$vuetify.goTo(0)"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </base-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',

    data: () => ({
      items: [
//        {
//          href: 'https://www.diyaudio.com/forums/solid-state',
//          icon: 'mdi-cast-audio',
//        }
      ],
    }),
  }
</script>
